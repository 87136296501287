import { render, staticRenderFns } from "./OrganismImageInfo.vue?vue&type=template&id=638f53fc&"
import script from "./OrganismImageInfo.vue?vue&type=script&lang=js&"
export * from "./OrganismImageInfo.vue?vue&type=script&lang=js&"
import style0 from "./OrganismImageInfo.vue?vue&type=style&index=0&id=638f53fc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesCommonMoleculeImageInfo: require('/app/components/molecules/common/MoleculeImageInfo.vue').default})
